$primary-color: #3498db;
$secondary-color: #2ecc71;
$accent-color: #f39c12;
.support {
  font-family: 'Arial', sans-serif;
  max-width: 800px !important;
  margin: 0 auto;
  padding-top: 80px;
}

.contact {
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #c54144;
  color: white;
  padding: 20px;
  .contact-info {
    text-align: center;
    p {
      font-weight: 800;
      margin: 10px 0;
      font-size: 18px;
    }
  }
  .contact-form {
    background-color: white;
    color: $secondary-color;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    h2 {
      color: $accent-color;
    }
    p {
      margin: 10px 0;
    }
  }
}

.content {
  color: #14539a;
  font-size: 25px;
  font-weight: 400;
  text-align: center;
  padding: 40px;
  h2 {
    color: $primary-color;
  }
}
