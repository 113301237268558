.user-avatar-container {
  position: relative;
  z-index: 9999;
  .user_profile {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: gray;
    margin-left: 0.5rem;
  }
  #store-list-in-header {
    position: absolute;
    top: 110%;
    display: block;
    left: -250px !important;
    width: 270px !important;
    @media (max-width: 360px) {
      top: 114%;
      display: block;
      left: -220px !important;
    }
    @media (max-width: 280px) {
      top: 114%;
      display: block;
      left: -200px !important;
    }
  }
  @media (max-width: 480px) {
    .dropdown-menu {
      width: 250px !important;
      left: -130px !important;
    }
  }
  @media (max-width: 290px) {
    .dropdown-menu {
      width: 200px !important;
      left: -130px !important;
    }
    #store-list-in-header {
      width: 240px !important;
    }
  }
  .dropdown-menu {
    border-radius: 0.5rem;
    z-index: 1;
    display: block;
    width: 360px;
    background: rgb(255, 255, 255);
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
    li {
      margin: 5px 0;
      padding: 8px;
      &:hover {
        border-radius: 0.4rem;
        background-color: #eee;
      }
      .navigations {
        margin-left: 0.5rem;
        font-size: 0.9rem;
        font-weight: 600;
      }
      .icon-cnt {
        background: #e5e7eb;
        width: 35px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        .react-icon {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }
    a {
      display: flex;
      align-items: center;
      // padding: 5px 10px;
      text-decoration: none;
      color: #333;
    }
  }
}

.fixed_header {
  border-radius: 1rem;
  margin-top: 1rem;
  z-index: 2;
  padding: 20px;
  margin: 0 auto;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .hamburger {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    width: 34px;
    height: 34px;
    font-size: 1.3rem;
    transition: all 0.2s ease-in-out 0s;
    color: #14539a;
  }
  .cross_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    width: 34px;
    height: 34px;
    font-size: 1.3rem;
    transition: all 0.2s ease-in-out 0s;
    color: #14539a;
  }
  .cart_icon {
    width: 24px;
  }
}

.sidebar {
  position: fixed;
  top: 77px;
  left: 243px;
  overflow-y: scroll;
  background: white;
  transition-duration: 1s;
  width: 240px;
  z-index: 912999999999;
  padding: 0 12px;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  @media (max-width: 480px) {
    left: 23px;
  }
}

.login {
  color: #313131;
  font-weight: 500;
  margin-left: 0.9rem;
}

.start-btn {
  color: #14539a;
  font-weight: 480;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav_item {
  margin: 1rem 0;
  a {
    display: flex;
    align-items: center;
    color: #14539a;
    font-size: 0.89rem;
    padding: 10px 16px 10px 24px;
    span {
      font-weight: 550;
      margin-left: 0.6rem;
    }
  }
}

.notification-btn {
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #fff;
  color: #333;
  font-size: 12px;
  cursor: pointer;
}

.read-btn:hover,
.unread-btn:hover {
  background-color: #eee;
}

.stores {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin-left: 0.3rem;
  margin-right: 0.9rem;
}

.user_profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #ddd;
}

// bottom navbar
.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  left: 0px;
  ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0;
    li {
      list-style: none;
      a {
        color: #14539a;
        font-size: 16px;
        display: block;
        text-align: center;
        &:hover {
          color: darken(#14539a, 10%);
        }
      }
    }
  }
}

// .user-avatar-container {
//   position: relative;
//   .user_profile {
//     width: 30px;
//     height: 30px;
//     border-radius: 50%;
//     background-color: gray;
//     margin-left: 0.5rem;
//   }
//   .dropdown-menu {
//     border-radius: 0.5rem;
//     z-index: 1;
//     display: block;
//     width: 360px;
//     background: rgb(255, 255, 255);
//     box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
//       rgb(0 0 0 / 30%) 0px 3px 7px -3px;
//     ul {
//       list-style: none;
//       padding: 0;
//       margin: 0;
//     }
//     li {
//       margin: 5px 0;
//       padding: 8px;
//       &:hover {
//         border-radius: 0.25rem;
//         background-color: #eee;
//       }
//       .navigations {
//         margin-left: 0.5rem;
//         font-size: 0.9rem;
//         font-weight: 600;
//       }
//       .icon-cnt {
//         background: #e5e7eb;
//         width: 35px;
//         border-radius: 50%;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         height: 35px;
//         .react-icon {
//           width: 1.4rem;
//           height: 1.4rem;
//         }
//       }
//     }
//     a {
//       display: flex;
//       align-items: center;
//       // padding: 5px 10px;
//       text-decoration: none;
//       color: #333;
//     }
//   }
// }
// .fixed_header {
//   background-color: white;
//   height: 60px;
//   box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   position: fixed;
//   width: 100%;
//   z-index: 99999999999;
//   padding: 0 20px;
//   .hamburger:hover,
//   .cross_icon:hover {
//     background: #14539a;
//     color: rgb(222 230 240);
//   }
//   .hamburger {
//     // position: relative;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     cursor: pointer;
//     border-radius: 8px;
//     width: 34px;
//     height: 34px;
//     font-size: 1.3rem;
//     transition: all 0.2s ease-in-out 0s;
//     background: rgb(222 230 240);
//     color: #14539a;
//   }
//   .cross_icon {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     cursor: pointer;
//     border-radius: 8px;
//     width: 34px;
//     height: 34px;
//     font-size: 1.3rem;
//     transition: all 0.2s ease-in-out 0s;
//     background: rgb(222 230 240);
//     color: #14539a;
//   }
//   .cart_icon {
//     width: 24px;
//   }
// }
// .sidebar {
//   position: fixed;
//   top: 59px;
//   background: white;
//   transition-duration: 1s;
//   height: 100vh;
//   width: 240px;
//   z-index: 912999999999;
//   padding: 0 12px;
//   box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
// }
// .login {
//   margin-left: 0.9rem;
// }
// .nav_item {
//   margin: 1rem 0;
//   a {
//     display: flex;
//     box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
//     border-radius: 0.5rem;
//     align-items: center;
//     color: #14539a;
//     font: 0.89rem;
//     // background: rgb(222 230 240);
//     padding: 10px 16px 10px 24px;
//     span {
//       margin-left: 0.6rem;
//     }
//   }
// }
// .notification-btn {
//   padding: 5px 10px;
//   border: 1px solid #ddd;
//   border-radius: 3px;
//   background-color: #fff;
//   color: #333;
//   font-size: 12px;
//   cursor: pointer;
// }
// .read-btn:hover,
// .unread-btn:hover {
//   background-color: #eee;
// }
// .stores {
//   width: 30px;
//   height: 30px;
//   border-radius: 4px;
//   margin-left: 0.3rem;
//   margin-right: 0.9rem;
// }
// .user_profile {
//   border: 1px solid #ddd;
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   object-fit: cover;
// }
