.account-settings-container {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding-top: 80px;
    padding-left: 10px;
    padding-right: 10px;
    /* Large devices (lg) */
    @media (max-width: 1200px) {
        max-width: 1100px;
        width: 100%;
    }
    /* Medium devices (md) */
    @media (max-width: 992px) {
        max-width: 910px;
        width: 100%;
    }
    /* Small devices (sm) */
    @media (max-width: 768px) {
        max-width: 760px;
        width: 100%;
    }
    /* Extra Small devices (xs) */
    @media (max-width: 576px) {
        max-width: 570px;
        width: 100%;
    }
    @media (max-width: 380px) {
        max-width: 370px;
        width: 100%;
    }
    @media (max-width: 280px) {
        max-width: 275px;
        width: 100%;
    }
    .active-nav {
        background-color: #f0f0f0 !important;
        color: #14539a !important;
    }
    .navigation {
        width: 20%;
        border-right: 0.5px solid #ddd;
        .menu {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin: 8px;
            padding: 8px;
            cursor: pointer;
            transition: background-color 0.2s ease;
            font-size: 16px;
            color: #333;
            flex-wrap: wrap;
            border-radius: 8px;
            &:hover {
                background-color: #f0f0f0;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }
            .icon {
                margin-right: 10px;
                background: #e5e7eb;
                width: 35px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 35px;
                font-size: 1.4rem;
            }
        }
        .submenu {
            cursor: pointer;
            cursor: pointer;
            transition: background-color 0.2s ease;
            font-size: 16px;
            color: #333;
            border-radius: 8px;
            .menu {
                font-size: 16px;
            }
            .icon {
                margin-right: 10px;
                background: #e5e7eb;
                width: 28px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 28px;
                font-size: 1rem;
            }
        }
    }
}

// form styles
.user-profile-form {
    width: 70%;
    display: flex;
    flex-direction: column;
    @media (max-width: 576px) {
        width: 97% !important;
    }
    .email-n-phone-number-cnt {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @media (max-width: 576px) {
            flex-direction: column;
            align-items: stretch;
        }
    }
    input {
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
    }
    .user-profile-update-cnt {
        display: flex;
        justify-content: center;
    }
    .form-control {
        display: flex;
        flex-basis: 45%;
        flex-direction: column;
    }
}

.update-btn {
    padding: 10px 20px !important;
    width: 5.7rem !important;
}

// store profile form
.store-profile-form {
    width: 70% !important;
    @media (max-width: 576px) {
        width: 97% !important;
    }
    @media (max-width: 380px) {
        width: 97% !important;
    }
    @media (max-width: 280px) {
        width: 97% !important;
    }
    @media (max-width: 639px) {
        .button-primary,
        .button-primary-disabled {
            height: 31px !important;
            width: 10rem !important;
            font-size: 13px !important;
        }
    }
    .store-profile-update-cnt {
        display: flex;
        justify-content: end;
    }
}

// store-contact
.store-legal-form {
    @media (max-width: 576px) {
        .button-primary {
            width: 7rem !important;
            height: 35px !important;
            font-size: 12.5px !important;
        }
    }
}

// go-back-button
.go-back-button {
    display: none;
    background-color: white;
    /* Green */
    color: #14539a;
    border-radius: 50px;
    text-align: center;
    text-decoration: none;
    font-size: 22px;
    cursor: pointer;
    transition: background-color 0.3s;
    @media (max-width: 576px) {
        display: inline-block;
    }
}