:root {
  --disabled-color-of-button: #e5e7eb;
  --second-priority-color: #14539a;
}

input[type='checkbox'] {
  display: none;
}

.convert-update-to-pending-btn-cnt {
  display: flex;
  justify-content: end;
  .convert-update-to-pending-btn {
    font-size: 17px;
    color: var(--second-priority-color);
    margin-top: -5px;
    svg {
      font-weight: 700;
    }
  }
}

.confirm-order-btn {
  background: rgb(20, 83, 154);
  width: 326px;
  padding: 0.4rem 0px;
  color: white;
  margin: 1rem 0px;
  font-weight: 600;
  border-radius: 21px 7px;
}

.mrp-sm-screen-mobile {
  display: none;
}

.tax-cnt {
  display: flex;
  flex-direction: column;
}

.cnt_of_order {
  width: 100%;
  margin: 0 auto;
  padding: 32px;
  padding-top: 90px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.minimum_quantity_info {
  background: #343440;
  color: #ececf1;
  position: absolute;
  top: 100%;
  right: 50%;
  font-size: 0.66rem;
  border-radius: 0.5rem;
  padding: 0.4rem;
  text-align: center;
  display: none;
  transition-duration: 2s;
  transition-timing-function: linear;
  transition-delay: 2s;
}

.minus-1:hover ~ .minimum_quantity_info {
  display: inline-block;
}

.minus {
  display: inline-block;
  padding: 0 0.5rem;
  border-radius: 0.3rem;
}

.filterIcon {
  z-index: 8;
  position: fixed;
  bottom: 18px;
  right: 18px;
  font-weight: 900;
  font-size: 19.5px;
  display: none;
  align-items: center;
  justify-content: center;
  color: var(--product-bg-color);
  width: 40px;
  height: 40px;
  background: var(--main-color);
  border-radius: 70px;
}

.cnt-of-seller {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 480px) {
    align-items: stretch;
    flex-direction: column;
  }
}

.filters {
  background-color: white;
  flex-basis: 30%;
  border-radius: 0.5rem;
  padding: 0 22px;
  .heading-filter-n-cross-icon {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(209, 208, 208, 0.877);
    h2 {
      font-size: 16px;
      padding-bottom: 8px;
      padding-left: 20px;
    }
    .go-back-button {
      display: none;
      background-color: white;
      /* Green */
      color: #14539a;
      border-radius: 50px;
      text-align: center;
      text-decoration: none;
      font-size: 22px;
      cursor: pointer;
      padding-bottom: 12px;
      transition: background-color 0.3s;
      @media (max-width: 480px) {
        display: inline-block;
      }
    }
  }
  .cnt_of_brand_distributor,
  .cnt_of_brand_distributor-mobile {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 20px;
    border-radius: 0.5rem;
    .apply-filter-btn {
      height: 37px;
      width: 100%;
      background-color: rgba(38, 102, 177);
      border: none;
      border-radius: 20px;
      color: white;
      padding: 5px 10px;
      font-size: 13px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: darken(rgba(38, 102, 177), 10%);
      }
    }
  }
  .cnt_of_brand_distributor-mobile {
    position: fixed;
    top: -20px;
    width: 100vw;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 20px;
    border-radius: 0.5rem;
  }
}

.cnt_lists_of_distributors {
  flex-basis: 66%;
  background-color: white;
  border-radius: 0.5rem;
  padding: 0 22px;
  .cnt_buy_again {
    border-bottom: 1px solid #ddd;
    .order_page_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.94rem;
      li {
        padding-bottom: 13px;
        color: #14539a;
        flex-basis: 25%;
        text-align: center;
      }
      .active-nav {
        font-weight: 650;
        width: 100%;
        border-bottom: 1.2px solid #14539a;
      }
    }
  }
}

.margin-left {
  margin-left: 1rem;
}

@media (max-width: 480px) {
  .margin-left {
    margin-left: 0rem;
  }
  .total-price-n-place-order-btn-cnt {
    margin-top: 15px;
    justify-content: center !important;
    align-items: center;
    flex-direction: column-reverse;
    flex-basis: 100% !important;
  }
}

.each_distributor {
  margin-top: 2rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #fefeff;
  color: rgb(18, 25, 38);
  padding: 0.8rem;
  border-radius: 0.25rem;
  margin-top: 1rem;
  padding-bottom: 0px;
  @media (max-width: 480px) {
    padding: 10px !important;
    flex-wrap: wrap;
    .margin-left-20 {
      flex-wrap: wrap;
      margin-left: 0px !important;
      margin-top: 20px !important;
    }
  }
  .margin-left-20 {
    margin-left: 20px;
  }
  .place-order-btn-cnt-mb {
    display: flex;
    justify-content: center;
    .place-order-btn-mb {
      background: #14539a;
      width: 150px;
      padding: 0.2rem 0px;
      font-size: 13px;
      display: none;
      color: white;
      position: absolute;
      top: 35px;
      right: -60px;
      height: 28px;
      border-radius: 21px 7px 21px 7px;
      @media (max-width: 480px) {
        display: block;
        position: static;
        margin: 5px 0;
      }
    }
  }
  .place-order-btn {
    background: #14539a;
    width: 100px;
    padding: 0.2rem 0px;
    font-size: 13px;
    color: white;
    position: absolute;
    top: 35px;
    right: -60px;
    height: 28px;
    border-radius: 21px 7px 21px 7px;
    @media (max-width: 480px) {
      display: none;
      position: static;
      margin: 5px 0;
    }
  }
  .seller-profile-img {
    border-radius: 0.5rem;
    object-fit: contain;
    height: 80px;
    width: 80px;
    @media (max-width: 480px) {
      height: 150px;
      width: 200px;
    }
  }
  @media (max-width: 480px) {
    .user_icon {
      align-self: center;
    }
  }
  .user_badage {
    font-size: 80px;
    background: rgb(222, 230, 240);
    color: white;
    border-radius: 50%;
  }
  .cnt_of_date_n_name {
    display: flex;
    @media (max-width: 480px) {
      justify-content: center;
      .distributor_name {
        font-size: 16px;
        margin: 0.5rem 0;
        margin-bottom: -0.5rem;
      }
    }
  }
  .date {
    font-size: 0.89rem;
    color: #606465;
    flex-basis: 100%;
    @media (max-width: 480px) {
      margin-bottom: 0.5rem;
    }
  }
}

.see_products {
  color: var(--second-priority-color);
  font-size: 0.89rem;
  text-align: center;
  @media (max-width: 480px) {
    margin: 0.45rem 0;
  }
}

.distributor_name {
  margin-bottom: -1.45rem;
  font-weight: 600;
  color: var(--second-priority-color);
}

.cnt_of_brand_n_total_products {
  display: flex;
  align-items: center;
  .total_products {
    margin-left: 10rem;
  }
}

.cnt_of_tax_n_total_item {
  margin-left: 50px;
  flex-basis: 32%;
  display: flex;
  @media (max-width: 480px) {
    margin-left: 0;
    flex-basis: auto;
  }
  .order_tax {
    font-size: 0.81rem;
    display: inline-block;
    color: #565858;
    span {
      font-weight: 550;
    }
    @media (max-width: 480px) {
      margin-bottom: 0.5rem;
    }
  }
}

.seller-total-price {
  margin-top: 80px;
  text-align: right;
  @media (max-width: 480px) {
    text-align: center;
    margin-top: 0px;
  }
}

.cnt_of_order_btn_n_total_price {
  flex-basis: 32%;
  @media (max-width: 480px) {
    flex-basis: 50%;
  }
  .total_price {
    margin-right: 1rem;
    color: rgb(18, 25, 38);
    font-weight: 500;
    font-size: 17px;
    span {
      font-style: italic;
      font-size: 0.75rem;
    }
  }
  a {
    margin-left: 6rem;
    display: block;
  }
}

.total_item {
  color: #606465;
  font-weight: 480;
}

// Order items ui for web
.bill-pop-up {
  padding-top: 2rem;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
  .seller-img {
    width: 100px;
    height: 100px;
    border-radius: 13px;
  }
  .seller-info-icon {
    margin-right: 5px;
    color: #364daa;
  }
  .cnt-of-seller-img-n-seller-info {
    display: flex;
    align-items: center;
    .seller-name-n-email {
      margin-left: 16px;
    }
  }
  @media (max-width: 480px) {
    .bill-pop-up {
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      top: 0;
      left: 0;
      position: fixed;
      z-index: 1500;
      display: flex;
      justify-content: flex-start;
      /* Align content to the top */
      align-items: flex-start;
      /* Align content to the top */
    }
    .bill-cnt {
      width: 100%;
      max-width: 480px;
      /* Set the maximum width for the content */
      background-color: #fff;
      padding: 20px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      overflow-y: auto;
      /* Enable vertical scrolling if content overflows */
      max-height: calc(100vh - 120px);
      /* Set max-height for content to fit within the viewport */
      box-sizing: border-box;
    }
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .bill-cnt {
    width: 90vw;
    background-color: white;
    border-radius: 6px;
    padding: 2rem;
    padding-bottom: 64px;
    @media (max-width: 480px) {
      padding: 9px;
    }
    .cross-icon-cnt {
      display: flex;
      justify-content: end;
      .cross-icon {
        display: block;
        color: red;
        font-size: 18px;
      }
    }
    .bill-table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
      tr {
        border-radius: 0.5rem;
      }
      .order-item-name {
        font-size: 0.89rem;
        font-weight: 450;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      th,
      td {
        padding: 10px;
        text-align: center;
        // border-bottom: 1px solid #ccc;
      }
      th {
        color: #6d7785;
        font-size: 13.5px;
        font-weight: 550;
        background-color: #f2f2f2;
      }
      .product_img {
        object-fit: contain;
        width: 50px;
        height: 50px;
        margin-right: 0.5rem;
      }
    }
  }
}

// Order items ui for mobile
.order-items-mb {
  .order-item-detail {
    padding: 6px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
    .flex {
      display: flex;
    }
    .justify-between {
      justify-content: space-between;
    }
    .padding-6 {
      padding: 4.5px;
    }
  }
  .img-n-name {
    display: flex;
    align-items: center;
    .order-item-name {
      margin-left: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .order-item-img {
      object-fit: contain;
      width: 40px;
      height: 40px;
      margin-right: 0.5rem;
    }
  }
}

.quantity {
  position: relative;
  display: flex;
  margin: 0.25rem 0;
  div {
    background-color: white;
    border-radius: 0.375rem;
    display: inline-block;
    padding: 0.1rem;
    .minimum_quantity_info {
      background: #343440;
      color: #ececf1;
      position: absolute;
      top: 100%;
      left: 0;
      font-size: 0.66rem;
      border-radius: 0.5rem;
      padding: 0.4rem;
      text-align: center;
      display: none;
      transition-duration: 2s;
      transition-timing-function: linear;
      transition-delay: 2s;
    }
    .minus-1:hover ~ .minimum_quantity_info {
      display: inline-block;
    }
    .minus {
      display: inline-block;
      padding: 0 0.4rem;
      border-radius: 0.3rem;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      display: none;
    }
    input {
      display: inline-block;
      width: 50px;
      border-radius: 4px;
      margin: 0 0.5rem;
      height: 22px;
      background-color: var(--product-bg-color);
      text-align: center;
      font-size: 0.875rem;
      padding: 0.5rem;
    }
    .plus {
      display: inline-block;
      padding: 0 0.5rem;
      border-radius: 0.3rem;
    }
    .plus:hover,
    .minus:hover {
      background-color: var(--product-bg-color);
    }
  }
}

.options_like_box_bundle_piece {
  max-width: 320px;
  width: 100%;
  display: block;
  margin: 0.25rem 0;
  padding: 0.25rem;
  background-color: white;
  border-radius: 0.25rem;
  font-size: 0.7rem;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button {
    flex-basis: 30%;
    border-radius: 0.375rem;
    padding: 0.15rem 0.55rem;
    margin: 0 0.2rem;
    text-align: center;
    @media (max-width: 350px) {
      flex-basis: auto;
    }
    @media (max-width: 280px) {
      padding: 3px;
    }
  }
  button:hover {
    background-color: var(--product-bg-color);
  }
}

.product_cnt {
  margin: 1rem 0;
  .order_date,
  .order_tax,
  .order_id {
    font-size: 0.81rem;
    display: inline-block;
    color: #565858;
    span {
      font-weight: 550;
    }
  }
}

.cancel_order {
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--second-priority-color);
  font-size: 0.84rem;
}

.orders {
  padding: 0 0 0.8rem 0.8rem;
  .product {
    position: relative;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    display: flex;
    border-radius: 0.4rem;
    padding: 0.8rem;
    margin-bottom: 0.8rem;
    align-items: center;
    background-color: #eef2f6;
    .cnt_of_product_img_n_quantity {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .product_img {
      height: 100px;
      width: 100px;
      object-fit: contain;
      border-radius: 0.25rem;
    }
    .title {
      font-size: 0.96rem;
      font-weight: 650;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .mrp {
      margin-top: -1.5rem;
      font-size: 1rem;
      font-size: 0.8rem;
      font-weight: 500;
      margin-bottom: 0.2rem;
    }
    .gst {
      font-size: 0.65rem;
      color: gray;
      font-weight: 480;
      display: inline;
    }
    .price {
      color: rgb(18, 25, 38);
      font-weight: 400;
      font-size: 13px;
      .total_span {
        font-style: italic;
        font-size: 0.75rem;
      }
    }
    .options_like_box_bundle_piece {
      background-color: white;
      display: inline-flex;
      font-size: 0.8rem;
      justify-content: space-around;
      padding: 0.15rem;
      border-radius: 0.375rem;
      width: 250px;
      button:hover {
        background-color: #eef2f6;
      }
      button {
        width: 65px;
        border-radius: 0.375rem;
      }
    }
    .delete_btn_cnt {
      text-align: right;
    }
    .delete_btn {
      font-size: 0.8rem;
      border-radius: 0.375rem;
      line-height: 0.8rem;
      height: 27px;
      color: #14539a;
      display: inline-flex;
      align-items: center;
      span {
        margin-left: 0.3rem;
      }
    }
  }
}

.marginLeft {
  margin-left: 14px;
  align-items: center;
}

@media (max-width: 370px) {
  .orders {
    .marginLeft {
      margin-left: 0px;
      align-items: center;
      flex-direction: column;
    }
    .title {
      text-align: center;
    }
    .price {
      text-align: center;
    }
    .quantity {
      justify-content: center;
    }
    .order_id {
      display: flex;
      justify-content: center;
    }
    .mrp-sm-screen-mobile {
      display: block;
      margin-bottom: 0.5rem;
      text-align: center;
    }
    .mrp {
      display: none;
    }
    .tax-cnt {
      display: flex;
      flex-direction: row;
      width: 200px;
      .tax {
        flex-basis: 50%;
        margin-right: 0.2rem;
        text-align: center;
      }
    }
  }
}

@media (max-width: 480px) {
  .heading-mb {
    font-size: 13px;
    color: #6e6969;
    font-weight: 650;
  }
  .basis-50-mb {
    flex-basis: 50%;
  }
  .orders {
    padding: 0;
    .marginLeft {
      margin-left: 0px;
      align-items: center;
    }
    .product {
      flex-direction: column;
      padding: 0.6rem;
      margin-bottom: 0.6rem;
      align-items: stretch;
      .cnt_of_product_img_n_quantity {
        margin-bottom: 0.4rem;
      }
      .cnt_of_options_n_total_price {
        justify-content: center;
        flex-basis: 100%;
        margin-top: 13px;
      }
      .options_like_box_bundle_piece {
        width: 100%;
        margin-top: 0.4rem;
        button {
          width: 70px;
          font-size: 0.7rem;
        }
      }
      .quantity {
        div {
          button {
            padding: 0 0.3rem;
            font-size: 0.7rem;
          }
          input {
            width: 35px;
            height: 18px;
            font-size: 0.7rem;
            padding: 0.3rem;
          }
        }
      }
      .product_img {
        height: 120px;
        width: 120px;
      }
      .title {
        font-size: 0.7rem;
      }
      .mrp {
        font-size: 0.7rem;
        margin-top: -0.8rem;
        margin-bottom: 0.1rem;
      }
      .order_date,
      .order_tax,
      .order_id {
        font-size: 0.6rem;
        span {
          font-weight: 500;
        }
      }
      .delete_btn {
        font-size: 0.6rem;
        line-height: 0.6rem;
        height: 20px;
        span {
          margin-left: 0.2rem;
        }
      }
    }
  }
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}

.label[data-shrink='false']
  + .MuiInputBase-formControl
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0.75rem 1.25rem;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0.75rem 1.25rem !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 1rem !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #e5e7eb !important;
  border-radius: 0.35rem !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #9fa6b3 !important;
}

@media (max-width: 780px) {
  // body {
  //   font-size: 12px;
  // }
  .order_page_header {
    font-size: 0.9rem;
  }
  .cnt_of_order {
    padding: 0;
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .filterIcon {
    display: flex;
  }
  .cnt_of_brand_distributor {
    display: none;
  }
  .cnt_of_brand_distributor-mobile {
    width: 100vw;
    height: 110vh;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 9;
    background-color: white;
    width: 89%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 22px;
    @media (max-width: 480px) {
    }
  }
  .cnt_lists_of_distributors {
    padding: 48px 22px 22px 22px;
    @media (max-width: 480px) {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 40px !important;
    }
  }
  .order_page_header {
    font-size: 0.75rem !important;
    justify-content: space-between !important;
  }
  .product {
    .product_img {
      height: 150px;
      width: 150px;
    }
    .title {
      font-size: 0.8rem;
    }
    .mrp {
      font-size: 0.75rem;
    }
    .price {
      margin: 5px 0;
      font-size: 14px;
    }
    .order_date,
    .order_id {
      margin: 5px 0;
      font-size: 0.7rem !important;
    }
  }
}

@media (max-width: 300px) {
  .mrp {
    font-size: 8.6px;
  }
  .order_date {
    font-size: 8.5px !important;
  }
}

// notification icons styles
.notification-icon .notification-menu::before {
  content: '';
  position: absolute;
  top: -10px;
  right: 15px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #dde6f0 transparent;
}

.notification-icon {
  position: relative;
  margin-right: 1.2rem;
}

.notification-icon i {
  font-size: 24px;
  color: #333;
}

.notification-icon .badge {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: #f44336;
  color: #fff;
  border-radius: 50%;
  padding: 3px 6px;
}

.notification-icon .notification-menu {
  position: absolute;
  top: 130%;
  right: -12px;
  width: 200px;
  background-color: #dde6f0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.notification-icon:hover .notification-menu {
  opacity: 1;
  visibility: visible;
}

.notification-icon .notification-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.notification-icon .notification-menu ul li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.notification-icon .notification-menu ul li:last-child {
  border-bottom: none;
}

.notification-icon .notification-menu ul li a {
  color: #333;
  text-decoration: none;
}

.notification-icon .notification-menu ul li a:hover {
  text-decoration: underline;
}
