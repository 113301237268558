.custom-select-cnt {
  position: relative;
  .arrow {
    font-size: 16px;
  }
  .label {
    width: 90%;
    display: flex;
    justify-content: space-between;
    background-color: white;
    position: absolute;
    top: 24%;
    margin-left: 1.3rem;
    color: #9ea6b3;
    transition: all 0.3s ease;
  }
  .label-should-move-upward {
    color: #3777c0;
    top: -10px;
    width: 13%;
    background: white;
    left: -11px;
    font-size: 13px;
    display: inline;
    padding: 0 5px;
    transition: all 0.3s ease;
  }
  .custom-dropdown {
    height: 50px;
    padding: 0.75rem 1.25rem;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 6px;
    border: 1px solid #e4e7eb;
    width: 100%;
    transition: border-color 0.3s ease;
    cursor: pointer;
    .selected-option {
      font-size: 14px;
      background-color: white;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .options {
      left: 0;
      bottom: -141px;
      border: 1px solid #ddd;
      border-top: 0px;
      position: absolute;
      z-index: 999;
      width: 100%;
      display: block;
      font-size: 14px;
      background-color: #fff;
      border-radius: 0 0 4px 4px;
      .option {
        margin: 0.5rem;
        border-bottom: 0.3px solid #ddd;
        padding: 7px;
        cursor: pointer;
        transition: background-color 0.2s;
        &:hover {
          border-radius: 7px;
          background-color: #f0f0f0;
        }
      }
    }
    &:hover .options {
      display: block;
    }
  }
}
