// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
@media (max-width: 480px) {
  .special-icon {
    width: 60px;
  }
}

.seller-list {
  padding: 4rem 0;
  @media (max-width: 480px) {
    padding: 0rem 0;
  }
  .arrow-right {
    font-size: 1.2rem;
    margin-top: -0.1rem;
  }
  .flex-49 {
    flex: 0 1 32%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    @media (max-width: 480px) {
      flex: 0 1 100%;
    }
  }
  .text-center {
    text-align: center;
  }
  .font-0 {
    font-size: 0;
  }
  .seller-list-container {
    width: 100%;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 480px) {
      padding: 0 0.7rem;
    }
  }
  .flex-49 {
    border-radius: 15px;
    position: relative;
    margin-bottom: 1.7rem;
  }
  .small-article {
    color: #313131;
    border-radius: 6px;
    position: absolute;
    padding: 1.4rem;
    bottom: 1.7rem;
    left: 1.7rem;
    right: 1.7rem;
    background-color: rgba(254, 254, 255, 0.89);
    z-index: 99;
    h4 {
      font-size: 1.3rem;
      font-weight: 700;
    }
    a {
      text-decoration: none;
      color: #676565;
      font-size: 0.8rem;
      font-weight: 600;
    }
    p {
      font-size: 0.99rem;
      font-weight: 450;
      margin: 1rem 0;
    }
    span {
      font-size: 0.6rem;
      font-weight: 200;
      margin-right: 0.2rem;
    }
  }
  .small-article:hover {
    background-color: #a33f42;
    transition-duration: 800ms;
    color: white;
  }
  .small-article:hover a {
    color: white;
  }
  .figure-1 img {
    border-radius: 6px;
    object-fit: scale-down !important;
    height: 400px;
    width: 100%;
  }
}

.small-description {
  font-size: 20px;
  line-height: 35px;
  font-weight: 460;
  color: #666464;
  margin-top: 1rem;
  margin-bottom: 40px;
  @media (max-width: 480px) {
    font-size: 17px;
    margin-bottom: 30px;
  }
}

.shop-now-btn {
  display: inline-block;
  padding: 0.8rem 1.8rem;
  background-color: #e9404a;
  color: white;
  font-weight: 480;
  font-size: 1.1rem;
  border-radius: 10px;
  transition: all 200ms ease-in-out;
}

.shop-now-btn:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  opacity: 0.8;
}

.home {
  padding-top: 80px;
  .more-info-section {
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .banner-2,
    .large-description {
      flex-basis: 45%;
      @media (max-width: 480px) {
        flex-basis: 100%;
        margin-bottom: 1rem;
      }
    }
  }
  .about-app {
    font-size: 16px;
    line-height: 20px;
    color: #4d4d4d;
    display: flex;
    @media (max-width: 480px) {
      margin-bottom: 1rem;
    }
    .playstore-img {
      width: 130px;
      height: 49px;
      vertical-align: bottom;
      margin-left: 0.5rem;
    }
  }
  .speciality-section-cnt {
    background-color: #ffbe98;
    .great {
      font-size: 1.6rem;
      font-weight: 440;
      @media (max-width: 480px) {
        font-size: 0.9rem;
      }
    }
    .speciality-section {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 2rem 0;
      @media (max-width: 480px) {
        flex-wrap: wrap;
      }
      .each-speciality {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 480px) {
          flex-basis: 45%;
          margin-bottom: 2.5rem;
        }
      }
      .active {
        border-radius: 20px;
        // background-color: rgb(233, 63, 74);
        padding: 2rem;
        // background-color: whitesmoke;
        @media (max-width: 480px) {
          padding: 0rem;
        }
      }
    }
  }
  @media (max-width: 480px) {
    padding: 10px;
    padding-top: 120px;
  }
  /* flex */
  .flex {
    display: flex;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-center {
    justify-content: center;
  }
  .wrap {
    flex-wrap: wrap;
  }
  /* columns */
  .flex-30 {
    flex: 0 1 30%;
  }
  @media (max-width: 480px) {
    .sm-flex-100 {
      flex-basis: 100% !important;
    }
    .sm-column {
      flex-direction: column !important;
    }
  }
  /* width */
  .width-full {
    width: 100%;
  }
  /* center */
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* flex */
  .flex {
    display: flex;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-center {
    justify-content: center;
  }
  .wrap {
    flex-wrap: wrap;
  }
  /* columns */
  .flex-20 {
    flex: 0 1 20%;
  }
  .flex-21 {
    flex: 0 1 21%;
  }
  .flex-23 {
    flex: 0 1 23.5%;
  }
  .flex-25 {
    flex: 0 1 25%;
  }
  .flex-30 {
    flex: 0 1 30%;
  }
  /* width */
  .width-full {
    width: 100%;
  }
  /* center */
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* padding */
  .padding {
    padding: 5rem 0;
  }
  .sell-banner {
    width: 500px;
    height: 350px;
    border-radius: 20px;
    @media (max-width: 480px) {
      max-width: auto !important;
      width: auto;
      height: 180px;
    }
  }
  .tagline-n-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 2rem;
    margin-bottom: 2rem;
    @media (max-width: 480px) {
      flex-direction: column;
      padding: 0rem;
    }
    .tagline {
      font-size: 2.7rem;
      font-weight: 800;
      margin-bottom: 0.6rem;
      color: #ac1f24;
      @media (max-width: 480px) {
        font-size: 1.85rem;
      }
    }
    .small-description {
      font-size: 20px;
      line-height: 35px;
      font-weight: 460;
      color: #666464;
      margin-top: 1rem;
      margin-bottom: 40px;
      @media (max-width: 480px) {
        font-size: 17px;
        margin-bottom: 20px;
      }
    }
    .cnt-of-tagline-n-small-description {
      flex-basis: 55%;
    }
  }
  .services {
    background: #fff8ec;
    margin-top: 2rem;
    border-radius: 0.5rem;
    padding: 5rem 0;
    .large-description {
      font-size: 18px;
      line-height: 34px;
      font-weight: 400;
      color: #4d4d4d;
      @media (max-width: 480px) {
        font-size: 16px;
      }
    }
    .stats {
      height: 4rem;
      width: 4rem;
      background: white;
      font-size: 1.4rem;
      color: #14539a;
      display: inline-flex;
      border: 1px solid #ddd;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-top: -6rem;
      transform: translateY(-1.4rem);
    }
    .flex-30 {
      background-color: white;
      margin-top: 4.4rem;
      box-shadow: 0 2px 8px rgba(26, 24, 30, 0.06);
      padding: 1.1rem;
      border-radius: 8px;
    }
    h4 {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      color: #1a181e;
      margin: 0 0 20px 0;
      text-align: center;
    }
    .flex-30:hover .stats {
      background-color: #2b2a68;
      color: white;
    }
    .flex-30 p {
      color: #4d4d4d;
      font-size: 17.5px;
      line-height: 30px;
      font-size: 0.94rem;
    }
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
}

// footer links
.footer-links {
  padding: 3rem 0 0 0;
  @media (max-width: 480px) {
    .footer {
      flex-direction: column !important;
    }
    .Useful-links {
      margin-top: 2rem;
    }
  }
  .footer .flexor h6 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.9rem;
    color: var(--subheadings);
  }
  h4 {
    font-size: 1rem;
    font-weight: 700;
    color: var(--subheadings);
  }
  .flexor address {
    margin-bottom: 1.4rem;
    margin-left: 0;
    color: var(--para);
  }
  .fa-angle-right {
    color: #14539a;
    margin-right: 0.1rem;
    font-size: 0.8rem;
  }
  .flexor span {
    color: var(--headings);
    font-weight: 620;
  }
  .Useful-links a {
    color: var(--para);
  }
  .Useful-links li {
    margin: 1rem 0;
  }
  .blank {
    border-radius: 10rem;
    height: 1rem;
    width: 100%;
    background-color: white;
    padding: 1.3rem 0;
    border: 1px solid #f7a88c;
  }
  .btn-cnt {
    position: relative;
  }
  .btn {
    position: absolute;
    top: 0;
    right: -0.1rem;
    bottom: 0;
    padding: 0 1.3rem;
  }
  .btn {
    border-radius: 10rem;
  }
  .flex-30 p {
    margin: 1.2rem 0 2rem 0;
    color: var(--subheadings);
  }
  .footer-nav li {
    background-color: #ac1f24;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    line-height: 2.3rem;
    text-align: center;
    margin-right: 0.9rem;
  }
  .footer-nav a {
    color: white;
    font-size: 1.2rem;
  }
  .footer-nav li:hover {
    opacity: 0.7;
  }
  small {
    font-size: 0.95rem;
  }
  small span {
    font-weight: 750;
    color: var(--subheadings);
  }
  cite {
    font-size: 0.89rem;
  }
  footer {
    padding: 1.6rem 0 0 0;
  }
  cite span {
    color: var(--secondary);
  }
  .top-btn {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    background-color: var(--secondary);
    position: fixed;
    right: 0.5rem;
    bottom: 1rem;
  }
  footer {
    position: relative;
  }
  .top-btn a {
    color: white;
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .footer-nav {
    margin: 1rem 0;
  }
}

/* heading */

.heading {
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #1a181e;
  margin-bottom: 1rem;
  @media (max-width: 480px) {
    font-size: 26px;
  }
}

@media (max-width: 480px) {
  .footer-social-links {
    flex-direction: column;
    align-items: center;
  }
}
