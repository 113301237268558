.registeration-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto 0;
  .registeration-form {
    max-width: 400px;
    width: 100%;
    @media (max-width: 300px) {
      max-width: 260px;
    }
  }
  .welcome-text {
    text-align: center;
    color: #14539a;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 1rem;
    @media (max-width: 300px) {
      font-size: 18px;
    }
  }
  .privacy-text {
    margin-left: 0.8rem;
    font-weight: 500;
    cursor: pointer;
  }
}

.verified-icon {
  position: absolute;
  top: 30%;
  right: 12px;
  color: green;
  font-size: 19px;
}
