.cnt-of-product-filter {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  padding: 8px;
  left: 0;
  position: fixed;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
  .arrow-down-icon {
    position: absolute;
    font-size: 25px;
    right: 7px;
    bottom: 8px;
    color: #898888;
    cursor: pointer;
  }
  @media (max-width: 639px) {
    .confirmation-box {
      padding: 19px;
      margin: 0.6rem;
      p {
        font-size: 0.9rem;
      }
      #button-primary,
      .button-primary {
        margin-left: 1rem !important;
        width: 5rem !important;
      }
      .button-primary,
      .cancel-btn {
        padding: 5px 10px;
        font-size: 12px !important;
      }
    }
  }
  .clear-all-btn {
    color: red;
    display: flex;
    justify-content: end;
    align-items: center;
    cursor: pointer;
    margin-top: 1rem;
    font-weight: 450;
  }
}

.filter-form {
  width: 450px;
  opacity: 1;
  height: auto;
  padding: 22px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: white;
  .suggestions-list {
    top: 72px;
  }
  .heading {
    font-weight: 600;
    font-size: larger;
    border-bottom: 1px solid #ddd;
    padding-bottom: 9px;
    display: flex;
    justify-content: space-between;
    color: var(--second-priority-color);
    .cross-icon {
      color: red;
    }
  }
  .input-label {
    align-self: start;
    font-size: 16px;
    color: #666666;
    margin-bottom: 5px;
    font-weight: 600;
  }
  .autocomplete-container {
    margin-bottom: 1rem;
  }
  .autocomplete-input {
    border: 1px solid #ddd;
    width: 100% !important;
  }
  .apply-filter-btn-cnt {
    margin-top: 0.6rem;
    .apply-filter-btn {
      height: 37px;
      width: 100%;
      background-color: rgb(38, 102, 177);
      border: none;
      border-radius: 20px;
      color: white;
      padding: 5px 10px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
  }
}

.cancel-btn {
  text-decoration: underline;
  color: #626d75;
  font-weight: 450;
}
