.public-store-profile-form {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: 70%;
  .cover-picture img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .profile-picture img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #fff;
    margin: -50px auto 20px;
    background-color: #f0f0f0;
  }
  .about-field textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
    min-height: 100px;
  }
}
