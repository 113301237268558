.confirmation-cnt {
  width: 100vw;
  height: 110vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 639px) {
    .confirmation-box {
      padding: 19px;
      margin: 0.6rem;
      p {
        font-size: 0.9rem;
      }
      #button-primary,
      .button-primary {
        margin-left: 1rem !important;
        width: 5rem !important;
      }
      .button-primary,
      .cancel-btn {
        padding: 5px 10px;
        font-size: 12px !important;
      }
    }
  }
}

.confirmation-box {
  width: 500px;
  opacity: 1;
  height: auto;
  padding: 32px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: white;
  p {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
  }
}

.cancel-btn {
  text-decoration: underline;
  color: #626d75;
  font-weight: 450;
}
