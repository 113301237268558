:root {
  --main-color: #14539a;
  --bordercolor: #e5e7eb;
  --product-bg-color: #eef2f6;
}

.subcart_closed_cnt {
  position: fixed;
  background-color: #f8f8f9;
  z-index: 99999;
  right: -400px;
  padding: 1rem 0.66rem;
  border-radius: 0.375rem;
  width: 350px;
  height: 100vh;
  transition-duration: 1s;
}

.subcart_opened_cnt {
  background-color: #f8f8f9;
  width: 350px;
  top: 0px !important;
  height: 100vh;
  z-index: 99999;
  position: fixed;
  right: 0px;
  padding: 1rem 0.66rem;
  border-radius: 0.375rem;
  transition-duration: 1s;
  padding-bottom: 120px;
}

.header_cnt_subcart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .close_btn {
    color: #9fa6b3;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .close_btn:hover {
    color: white;
    background-color: rgb(178, 18, 18);
  }
}

.subcart_product_cnt {
  margin: 2rem 0;
  .subcart_product_info_cnt {
    flex-basis: 58%;
    width: 100%;
  }
  .product_name_cnt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    .product_name {
      font-size: 0.89rem;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .product_img {
    height: 100%;
    margin-right: 2rem;
    flex-basis: 30%;
    border-radius: 0.5rem;
  }
  .cnt_of_price_n_mrp {
    display: flex;
    align-items: center;
    .mrp {
      font-size: 0.65rem;
      color: gray;
      font-weight: 480;
      margin-left: 0.45rem;
    }
    .price {
      color: rgb(18, 25, 38);
      font-weight: 500;
      font-size: 0.95rem;
    }
    .profit_percentage {
      font-size: 0.65rem;
      margin-left: 0.5rem;
      color: green;
    }
  }
  .quantity {
    position: relative;
    display: flex;
    margin: 0.4rem 0;
    justify-content: center;
    div {
      background-color: white;
      border-radius: 0.375rem;
      display: inline-block;
      padding: 0.2rem;
      .minimum_quantity_info {
        background: #343440;
        color: #ececf1;
        position: absolute;
        top: 100%;
        right: 50%;
        font-size: 0.66rem;
        border-radius: 0.5rem;
        padding: 0.4rem;
        text-align: center;
        display: none;
        transition-duration: 2s;
        transition-timing-function: linear;
        transition-delay: 2s;
      }
      .minus-1:hover ~ .minimum_quantity_info {
        display: inline-block;
      }
      .minus {
        display: inline-block;
        padding: 0 0.5rem;
        border-radius: 0.3rem;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        display: none;
      }
      input {
        display: inline-block;
        width: 50px;
        border-radius: 4px;
        margin: 0 0.5rem;
        height: 22px;
        background-color: var(--product-bg-color);
        text-align: center;
        font-size: 0.875rem;
        padding: 0.5rem;
      }
      .plus {
        display: inline-block;
        padding: 0 0.5rem;
        border-radius: 0.3rem;
      }
      .plus:hover,
      .minus:hover {
        background-color: var(--product-bg-color);
      }
    }
  }
  .options_like_box_bundle_piece {
    display: block;
    margin: 0.25rem 0;
    padding: 0.25rem;
    background-color: white;
    border-radius: 0.25rem;
    font-size: 0.7rem;
    div {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
    button {
      flex-basis: 30%;
      border-radius: 0.375rem;
      padding: 0.25rem;
      margin: 0 0.2rem;
      text-align: center;
    }
    button:hover {
      background-color: var(--product-bg-color);
    }
  }
  .profit_cnt {
    text-align: center;
    .total_price {
      font-size: 0.9rem;
      color: rgb(22, 21, 20);
      font-weight: 400;
    }
    .profit_price {
      font-size: 0.7rem;
      color: green;
    }
  }
  .star_info {
    margin: 0.4rem 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      flex-basis: 50%;
      text-align: center;
      font-size: 11px;
      margin: 0 0;
    }
  }
}

.checkout_cnt {
  position: fixed;
  box-shadow: 0px -5px 30px -10px rgb(0 0 0 / 15%);
  width: 100%;
  background-color: #eef2f6;
  margin-left: -0.6rem;
  padding: 12px;
  bottom: 0;
}

.small_cnt_of_subcart_product {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 428px) {
  .subcart_closed_cnt {
    position: fixed;
    background-color: #f8f8f9;
    z-index: 99999;
    right: -440px;
    padding: 1rem 0.66rem;
    border-radius: 0.375rem;
    width: 100vw;
    height: 100vh;
    transition-duration: 1s;
  }
  .subcart_opened_cnt {
    background-color: #f8f8f9;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    position: fixed;
    right: 0px;
    padding: 1rem 0.66rem;
    border-radius: 0.375rem;
    transition-duration: 1s;
  }
}
