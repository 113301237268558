.heading-of-route-page {
  font-size: 19px;
  font-weight: 600;
  text-align: center;
  width: 84%;
  margin: 0 auto;
  margin-bottom: 2rem;
  .cross-icon {
    cursor: pointer;
    color: red;
  }
}

.route-boxes-cnt {
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1500;
  //
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-top: 100px !important;
  padding-bottom: 16px;
  @media (max-width: 480px) {
    padding: 10px;
    padding-top: 100px;
  }
  $bg-color: #14539a;
  .circle {
    cursor: pointer;
    position: fixed;
    bottom: 44px;
    right: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    background-color: $bg-color;
    border-radius: 50%;
    .plus {
      position: absolute;
      font-size: 15px;
      color: white;
      font-weight: bold;
    }
  }
}

.middle-cnt {
  width: 80%;
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  .no-route-info {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 19px;
    font-weight: 500;
  }
  @media (max-width: 480px) {
    width: 100%;
    .make-active-btn {
      padding: 3px;
      font-size: 12px;
    }
  }
}

.route-box {
  flex-basis: 49%;
  opacity: 0.85;
  background: white;
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  padding: 12px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  position: relative;
  @media (max-width: 480px) {
    flex-basis: 100%;
    padding: 8px;
  }
  .links {
    font-weight: 700;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.map-icon {
  width: 79px;
  margin-bottom: 8px;
  height: 51px;
  align-self: center;
}

.route-details {
  text-align: center;
}

.route-name {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.route-description {
  font-size: 0.9rem;
  color: #888;
}

.edit-icon {
  width: 18px;
  height: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  filter: none !important;
  cursor: pointer;
  color: #14539a;
  z-index: 9;
}

.route-schedule,
.route-coverage,
.route-start-time,
.route-end-time,
.route-start-date {
  font-size: 16px;
  margin-top: 10px;
  color: #555;
  .label {
    font-size: 15px;
    font-weight: 600;
  }
}

.route-start-date {
  margin-bottom: 14px;
}

.route-box a {
  display: flex;
  flex-basis: 32%;
  align-items: center;
  margin-top: 10px;
  color: #14539a;
  font-size: 0.8rem;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  @media (max-width: 480px) {
    font-size: 11px;
  }
  .icon {
    font-weight: 800;
    margin-right: 0.4rem;
  }
}

.make-active-btn {
  display: inline-flex;
  padding: 6px;
  position: absolute;
  z-index: 9999;
  border-radius: 0.5rem;
  background-color: #f0f0f0;
  top: 39%;
  text-align: center;
  font-size: 15px;
  width: 100%;
  font-weight: 500;
  color: #14539a;
  justify-content: center;
}

.route-box a:hover {
  text-decoration: underline;
  color: #0d366f;
}
