.lists-of-stores {
  .store {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    padding: 8px;
    &:hover {
      border-radius: 0.5rem;
      background-color: #eee;
    }
  }
}
