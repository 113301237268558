:root {
  --main-color: #14539a;
  --bordercolor: #e5e7eb;
  --product-bg-color: #eef2f6;
}

.small_cnt {
  .MuiInputBase-root,
  .MuiOutlinedInput-root,
  .MuiInputBase-colorPrimary,
  .MuiInputBase-fullWidth,
  .MuiInputBase-formControl,
  .MuiInputBase-adornedEnd,
  .MuiAutocomplete-inputRoot,
  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 0px !important;
  }
}

.text-center {
  text-align: center;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.autocomplete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .autocomplete-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 260px;
    font-size: 16px;
  }
  .suggestions-list {
    list-style: none;
    padding: 0;
    margin: 0;
    top: 45px;
    width: 100%;
    max-height: 120px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    position: absolute;
    background-color: white;
    z-index: 9;
  }
  .suggestion-item {
    padding: 8px 16px;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.order_page_big_cnt {
  .small_cnt {
    width: 100%;
    padding: 0 32px;
    margin: 0 auto;
  }
  form {
    display: flex;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--bordercolor);
    justify-content: space-around;
    padding: 16px 0;
  }
  .search-bar-component {
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    .search-bar {
      display: flex;
      width: 60%;
      position: relative;
      height: 65px;
      align-items: center;
      background-color: #fff;
      border-radius: 14px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      @media (max-width: 480px) {
        width: 100%;
        height: 36px;
      }
      input[type='text'] {
        border: none;
        padding-left: 12px;
        flex-grow: 1;
        font-size: 18px;
        border-radius: 4px;
        outline: none;
      }
      .search-icon {
        color: #0073e6;
        background: none;
        border: none;
        cursor: pointer;
        padding: 10px;
        font-size: 25px;
        @media (max-width: 480px) {
          font-size: 15px;
        }
      }
      .product-list {
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
          rgba(17, 17, 26, 0.1) 0px 0px 8px;
        position: absolute;
        z-index: 99999;
        width: 100%;
        position: absolute;
        top: 100%;
        width: 100%;
        max-height: 300px;
        overflow-y: scroll;
        border: 1px solid #ddd;
        border-radius: 7px;
        margin-top: 9px;
        background: white;
        .each-product {
          margin: 0.5rem;
          padding: 0.6rem;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #dcdcdc;
          .product-img {
            margin-right: 1rem;
            height: 70px;
            width: 70px;
            border: 1px solid #ddd;
            border-radius: 50%;
            object-fit: contain;
          }
          .mrp {
            color: #64676b;
            font-size: 14px;
          }
          .product-name {
            color: #14539a;
            font-weight: 500;
          }
        }
        .each-product:hover {
          cursor: pointer;
          background-color: #f3f3f2;
          border-radius: 7px;
        }
      }
    }
  }
  .products_cnt {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 480px) {
      justify-content: center !important;
    }
  }
  .product {
    flex-direction: column;
    margin: 0.9rem;
    flex-basis: 23%;
    max-height: 100%;
    border-radius: 0.375rem;
    background-color: var(--product-bg-color);
    transition: all 0.7s ease-in-out;
    position: relative;
    &.out-of-stock {
      &::before {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        content: 'Out of Stock Out of Stock Out of Stock ';
        opacity: 0.2;
        position: absolute;
        top: 50%;
        z-index: 99;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0.5rem;
        border-radius: 0.25rem;
        background-color: rgba(255, 0, 0, 0.8);
        color: #fff;
        font-weight: bold;
      }
    }
    .product_img_container {
      &:hover {
        .arrows {
          z-index: 999;
          display: flex;
        }
      }
    }
    .product_img_container {
      display: flex;
      justify-content: center;
      position: relative;
      .arrows {
        height: 30px;
        font-size: 18px;
        width: 30px;
        border-radius: 50%;
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        color: #000;
        cursor: pointer;
        transition: color 0.3s, background-color 0.3s;
        &:hover {
          background-color: #dcdcdc;
        }
      }
      .left_arrow {
        left: 5px;
      }
      .right_arrow {
        right: 5px;
      }
    }
    .product_img {
      mix-blend-mode: darken;
      width: 300px;
      height: 190px;
      object-fit: scale-down;
      border-top-right-radius: 0.25rem;
      border-top-left-radius: 0.25rem;
    }
    .cnt_of_price_n_mrp {
      margin-top: 0.3rem;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .mrp {
        font-size: 0.65rem;
        color: gray;
        font-weight: 480;
        margin-left: 0.45rem;
      }
      .price {
        color: rgb(18, 25, 38);
        font-weight: 500;
        font-size: 18px;
      }
      .profit_percentage {
        font-size: 0.65rem;
        margin-left: 0.5rem;
        color: green;
      }
    }
  }
  .product_info_cnt {
    width: 100%;
    padding: 8px;
    @media (max-width: 380px) {
      padding: 0.3rem;
    }
    .profit_cnt {
      text-align: center;
      .total_price {
        color: rgb(18, 25, 38);
        font-weight: 500;
        font-size: 17px;
      }
      .profit_price {
        font-size: 0.7rem;
        color: green;
      }
    }
    .title {
      width: 100%;
      h1 {
        font-size: 0.96rem;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .quantity {
      position: relative;
      display: flex;
      margin: 0.25rem 0;
      justify-content: center;
      div {
        background-color: white;
        border-radius: 0.375rem;
        display: inline-block;
        padding: 0.2rem;
        .minimum_quantity_info {
          background: #343440;
          color: #ececf1;
          position: absolute;
          top: 100%;
          right: 50%;
          font-size: 0.66rem;
          border-radius: 0.5rem;
          padding: 0.4rem;
          text-align: center;
          display: none;
          transition-duration: 2s;
          transition-timing-function: linear;
          transition-delay: 2s;
        }
        .minus-1:hover ~ .minimum_quantity_info {
          display: inline-block;
        }
        .minus {
          display: inline-block;
          padding: 0 0.5rem;
          border-radius: 0.3rem;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          display: none;
        }
        input {
          display: inline-block;
          width: 50px;
          border-radius: 4px;
          margin: 0 0.5rem;
          height: 22px;
          background-color: var(--product-bg-color);
          text-align: center;
          font-size: 0.875rem;
          padding: 0.5rem;
        }
        .plus {
          display: inline-block;
          padding: 0 0.5rem;
          border-radius: 0.3rem;
        }
        .plus:hover,
        .minus:hover {
          background-color: var(--product-bg-color);
        }
      }
    }
    .options_like_box_bundle_piece {
      max-width: 320px;
      width: 100%;
      display: block;
      margin: 0.25rem 0;
      padding: 0.25rem;
      background-color: white;
      border-radius: 0.25rem;
      font-size: 0.7rem;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      button {
        flex-basis: 30%;
        border-radius: 0.375rem;
        padding: 0.25rem 0.95rem;
        margin: 0 0.2rem;
        text-align: center;
        @media (max-width: 350px) {
          flex-basis: auto;
        }
        @media (max-width: 280px) {
          padding: 7px;
        }
      }
      button:hover {
        background-color: var(--product-bg-color);
      }
    }
    .offers-popup-container {
      .show-offers-link {
        padding-bottom: 10px;
        font-size: 16px;
        color: var(--main-color);
        text-decoration: underline;
        cursor: pointer;
        @media (max-width: 380px) {
          font-size: 12px;
        }
      }
      .popup-overlay {
        z-index: 999;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
      }
      .popup-content {
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        max-width: 500px;
      }
      .popup-title {
        color: #333;
        font-size: 20px;
        font-weight: 550;
      }
      .offers-list {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 10px;
      }
      .offers-list > div {
        flex: 1;
        margin-right: 20px;
        margin: 0.6rem;
      }
      .close-popup-btn {
        font-size: 19px;
        color: #f44336;
        border: none;
        display: inline-block;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.2s;
      }
    }
    .range-of-prices-container {
      display: inline-block;
      .range-of-prices-link {
        font-size: 16px;
        padding-bottom: 0.6rem;
        color: var(--main-color);
        text-decoration: underline;
        cursor: pointer;
        @media (max-width: 380px) {
          font-size: 12px;
        }
      }
      .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
      }
      .popup-content {
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        max-width: 500px;
      }
      .popup-title {
        margin-bottom: 10px;
        color: #333;
        font-size: 20px;
      }
      .price-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 10px;
      }
      .price-table th,
      .price-table td {
        padding: 8px;
        text-align: left;
      }
      .price-table th {
        font-weight: 600;
      }
      .price-table tr:nth-child(even) {
        background-color: #f2f2f2;
      }
      .price-table tr:hover {
        background-color: #e0e0e0;
      }
      .close-popup-btn {
        margin-top: 20px;
        padding: 10px 20px;
        font-size: 16px;
        background-color: #f44336;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.2s;
        &:hover {
          background-color: #d32f2f;
        }
      }
    }
    .star_info {
      margin: 0.6rem 0 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 11px;
        margin: 0.25rem 0;
      }
    }
    .tick_mark_btn {
      display: inline-block;
      background: #14539a;
      color: white;
      font-size: 18px;
      margin: 0.3rem 0;
      line-height: 40px;
      text-align: center;
      width: 100%;
      height: 40px;
      border-radius: 21px 7px 21px 7px;
      border: 2.3px solid #14539a;
      transition: all 0.5s linear;
    }
    .add_to_bag_btn {
      transition: all 0.5s linear;
      background: white;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      margin: 0.3rem 0;
      width: 100%;
      height: 40px;
      border-radius: 21px 7px 21px 7px;
      border: 2.3px solid #14539a;
      @media (max-width: 380px) {
        margin-top: 1rem;
        height: 32px;
      }
      .add_to_bag_icon {
        display: inline-block;
        width: 30px;
      }
    }
    .update_bag_btn {
      transition: all 0.5s linear;
      background: white;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      margin: 0.3rem 0;
      width: 100%;
      height: 40px;
      border-radius: 21px 7px 21px 7px;
      border: 2.3px solid #14539a;
    }
  }
}

.css-1d21uqd-MuiAutocomplete-root,
.MuiOutlinedInput-root {
  padding: 0px;
}

.error_info {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: horizontal-shaking 0.5s;
  color: rgb(207, 17, 17);
  animation-iteration-count: infinite;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @keyframes horizontal-shaking {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(5px);
    }
    50% {
      transform: translateX(-5px);
    }
    75% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
}

@media (max-width: 1200px) {
  .parent_cnt_order_page {
    max-width: 1199px;
    width: 100%;
  }
}

@media (max-width: 1110px) {
  .parent_cnt_order_page {
    max-width: 1110px;
    width: 100%;
  }
  .products_cnt {
    .product {
      max-width: 30%;
      flex-basis: 30%;
      .product_img {
        object-fit: scale-down;
        width: 100%;
      }
    }
  }
}

@media (max-width: 950px) {
  .parent_cnt_order_page {
    max-width: 950px;
    width: 100%;
  }
  .search_bar_cnt {
    margin: 0.8rem 0;
  }
  .products_cnt {
    .product {
      max-width: 40%;
      flex-basis: 40%;
      .product_img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .parent_cnt_order_page {
    max-width: 767px;
    width: 100%;
  }
  // form {
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     justify-content: center;
  //     div {
  //         margin: 0.1rem 0;
  //     }
  // }
  .search_bar {
    width: 70%;
  }
  .products_cnt {
    .product {
      max-width: 45%;
      flex-basis: 45%;
      .product_img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 639px) {
  .parent_cnt_order_page {
    max-width: 639px;
    width: 100%;
    .small_cnt {
      padding: 0 6px;
    }
    .search_bar_cnt {
      .search_bar {
        width: 70%;
      }
    }
  }
  .products_cnt {
    .product {
      max-width: 60%;
      flex-basis: 60%;
      .product_img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 428px) {
  .parent_cnt_order_page {
    max-width: 428px;
    width: 100%;
    .search_bar_cnt {
      .search_bar {
        width: 90%;
      }
    }
  }
  .products_cnt {
    .product {
      max-width: 95%;
      flex-basis: 95%;
      .product_img {
        width: 100%;
      }
    }
  }
}
