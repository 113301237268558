.success-indicator {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9999999999999999;
  background-color: #4caf50;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 0;
  display: none;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in;
  @media (max-width: 639px) {
    .button-primary,
    .button-primary-disabled {
      padding: 5px 10px;
      font-size: 12px !important;
    }
  }
  &.visible {
    opacity: 1;
    display: flex;
  }
  .icon {
    display: inline-block;
    margin-right: 10px;
    font-size: 20px;
  }
  .message {
    display: inline-block;
  }
}
